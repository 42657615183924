import Header from "../Header/Header";
import PostcodeCheck from "../PostcodeCheck/PostcodeCheck";
import Footer from "../Footer/Footer";
import Eligible from "../Eligibile/Eligible";
import {BrowserRouter as Router, Route, Routes, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider} from "react-router-dom";
import Ineligible from "../Ineligible/Ineligible";
import Unrecognised from "../Unrecognised/Unrecognised";
import UnrecognisedScotlandEngland from "../UnrecognisedScotlandEngland/UnrecognisedScotlandEngland";
import OutsideScotland from "../OutsideScotland/OutsideScotland";
import PostcodeContext from "../../Contexts/PostcodeContext";
import { useState } from "react";

const App = () => {

    //get the postcodeData state using callback function in PostcodeCheck
    const [postcodeData, setPostcodeData] = useState('');

    const updatePostcodeData = (newPostcodeData: any) => {
        setPostcodeData(newPostcodeData);
    };

    return(
        <>
            <PostcodeContext.Provider value={postcodeData}>
                <div className="ds_page__top">
                    <Header />
                </div>
                <div className="ds_page__middle">
                    <div id="body-wrapper" className={"body-wrapper"}>
                        <div className="ds_wrapper">
                            <main id="main-content" className="ds_layout ds_layout--article">
                                <div className="ds_layout__content">
                                    <Router>
                                        <Routes>
                                            <Route path="/" element={
                                                <PostcodeCheck onUpdatePostcode={updatePostcodeData} />
                                            }>
                                            </Route>
                                            <Route path="/eligible" element={
                                                <Eligible />
                                            }>
                                            </Route>
                                            <Route path="/ineligible" element={
                                                <Ineligible />
                                            }>
                                            </Route>
                                            <Route path="/unrecognised" element={
                                                <Unrecognised />
                                            }>
                                            </Route>
                                            <Route path="/outside-scotland" element={
                                                <OutsideScotland />
                                            }>
                                            </Route>
                                            <Route path="/unrecognised-scotland-england" element={
                                                <UnrecognisedScotlandEngland />
                                            }>
                                            </Route >
                                            <Route path="*" element={() => <Navigate to="/" />}>
                                            </Route>
                                        </Routes>
                                    </Router>
                                </div>
                                <div
                                    className="ds_layout__feedback">
                                        <h2 id="feedback">Service feedback</h2>
                                        <a target="_blank" id="feedbackLink"
                                        href="https://response.questback.com/isa/qbv.dll/bylink?p=HXSMYVVeWmJmF93vwa9xaetfvK1PhgQAd3kwWK_uomIgvfp_VotYvXeFTt4fMBD8WxxZ2nRrXelrpXK12o6Qow2">Report
                                    a problem</a> with this online form
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
                <div className="ds_page__bottom">
                    <Footer />
                </div>
            </PostcodeContext.Provider>
        </>
    )
}

export default App
