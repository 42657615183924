const pilotAreaList = () => {

    const phaseTwoDate = new Date("June 24, 2024").getTime();
    const phaseThreeDate = new Date("August 19, 2024").getTime();
    //toShow set to pilot one areas as default
    let toShow: string[] = ['Dundee', 'Perth & Kinross', 'Western Isles'];
    const phaseTwoAreas = ['Angus', 'North Lanarkshire', 'South Lanarkshire'];
    const phaseThreeAreas = ['Fife', 'Aberdeen City', 'Aberdeenshire', 'Moray', 'East Ayrshire', 'North Ayrshire', 'South Ayrshire'];
    let today = new Date().getTime();



    if (today >= phaseThreeDate) {
        toShow = toShow.concat(phaseTwoAreas, phaseThreeAreas)
    }
    if (today >= phaseTwoDate && today < phaseThreeDate) {
        toShow = toShow.concat(phaseTwoAreas)
    }

    return (
        <>
            <ul>
                {
                    toShow.map((phaseArea, index) => {
                        return <li key={index}>{phaseArea}</li>
                    })
                }
            </ul>
        </>
    );
}

export default pilotAreaList;